import React from "react"
import Layout from "../components/Layout"
import { graphql, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import SEO from '../components/SEO'

export default function Home({data}) {
  console.log(data)
  const usluge = data.usluga.nodes
  const blog = data.blogs.nodes

  return (
    <Layout>
      <SEO title="Home" description="Centar za KBT, Gestalt, Shema psihoterapiju i savjetovanje u Novom Zagrebu. Usluge dostupne online i uživo na engleskom i hrvatskom jeziku." />
      <main className="page intro-page">
        <header  className="intro">
          <div id="intro_text">              
            <h1 className="apsihaNaslov"><strong>Presložimo se zajedno </strong></h1>
            <div className="hidden-lg"><StaticImage
            src="../assets/images/origami_hero_mob.png"
            alt="Origami_boat"
            className="boat_mob"
            placeholder="DOMINANT_COLOR"
            //layout="constrained"
            ></StaticImage>
            </div>
              <p>Preslagivanja su teška.</p>
              <p>Doći u kontakt s terapeutom koji osluškuje tvoje potrebe i pruža sigurno mjesto za istraživanje ne bi trebalo biti.</p>
              <p>Ovdje možeš dobiti fleksibilnu, stručnu, posvećenu i odvažnu podršku na tvom terapijskom putu.</p>
              <div>           
              <Link to="/Contact" className="btn">
              Kontaktiraj nas 
              </Link>
              </div>
          </div>
          </header>

          <div className="whiteSection boxedWhite" id="usluge">
          <div className="whiteSection-container">
            <div className="whiteSection-text">
            <h2 className="center"><strong>Naše usluge</strong></h2>
            <div className="tags-list" id="tags">
            {usluge.map(usluge =>( 
              <Link to={"../Services/" + usluge.frontmatter.slug} key={usluge.id}>
                <div className="tagThumb">
                <GatsbyImage
                  image={getImage(usluge.frontmatter.thumb.childImageSharp.gatsbyImageData)}
                  alt={usluge.frontmatter.title}
                  />
                  <div className="tagDescript">
                  <h3><strong>{usluge.frontmatter.title}</strong></h3>
                  <p>{usluge.frontmatter.showDescription}</p>
                  </div>
                </div>
              </Link>
            ))}
        </div>
          </div>
          </div>
        </div>
        <div className="blueSection boxedBlue" id="blue01">
          <div className="blueSection-container">
            <div className="blueSection-text">
              <div className="blueSection-columns1">
                <div className="boxedB">
                <h2><strong>Tu smo za tebe!</strong></h2>
                <p> Želja nam je bila stvoriti siguran kutak za osobe u procesu prilagodbe i veselimo se prilici da ti budemo suputnici. 
                  Kao i svaki izazov, prilagodba donosi preslagivanja, otkrivanje, istraživanje i unosi neku novu energiju.</p>
                <p>Od nas možeš očekivati stručnu podršku, povjerenje u tebe i tvoje mogućnosti, autentičnost i puno empatije. </p>
                <p>Mi smo tu da ti pružimo potporu u procesima:</p>
                <ul className="popisX">
                <li> osvještavanja potreba</li>
                <li> istraživanja novih puteva i</li>
                <li> postavljanju temelja za život vrijedan življenja</li>
                </ul>
                <p>Ako te zanima kojim temama se bavimo i u kojim područjima možemo biti od pomoći, više informacija možeš pronaći
                  <Link to="/About" className="about_link"> ovdje</Link>.</p>
                </div>
                <div>
                <StaticImage
                 src="../assets/images/Fortuneteller.png"
                  alt="Apsiha_"
                  className="blueSection-img"
                  placeholder="DOMINANT_COLOR"
                  layout="fullWidth"
                  ></StaticImage>
                </div>
              </div>
           </div>
          </div>
        </div>
        <div className="blogSection">
        <h2 className="center"><strong>Najnoviji Blogovi</strong></h2>
        <div className="blogs-list" id="blogs">
        {blog.map(({ frontmatter }) => {
          return (        
              <Link to={"../blog/" + frontmatter.slug} key={frontmatter.id}>
                <div className="BlogThumb boxedIn">
                  <GatsbyImage
                  image={getImage(frontmatter.thumb.childImageSharp.gatsbyImageData)}
                  alt={frontmatter.title}
                  />
                  <div className="BlogDescript">
                  <h4><strong>{frontmatter.title}</strong></h4>
                  <p>{frontmatter.description}</p>
                  <p className="kurziv">autor: {frontmatter.author}, {frontmatter.date}</p>
                  </div>
                </div>
              </Link>
          )
        })}
      </div>
        </div>
      </main>
    </Layout>
  )
}

export const query = graphql` 
query Usluge {
    usluga: allMarkdownRemark(
      sort: {fields: frontmatter___order, order: ASC}
      filter: {frontmatter: {type: {eq: "usluge_input"}}}
    ) {
      nodes {
        frontmatter {
          slug
          order
          title
          description
          showDescription
          thumb {
            childImageSharp {
              gatsbyImageData(
                placeholder: DOMINANT_COLOR
                transformOptions: {fit: CONTAIN}
                width: 450
              )
            }
          }
        }
        id
      }
    }
   blogs: allMarkdownRemark(
      limit: 3
      sort: {fields: frontmatter___date, order: DESC}
      filter: {frontmatter: {type: {eq: "blog_input"}}}
    ) {
      totalCount
      nodes  {
        frontmatter {
          slug
          title
          date
          description
          author
          id
          thumb {
            childImageSharp {
              gatsbyImageData(
                placeholder: DOMINANT_COLOR
                transformOptions: {fit: CONTAIN}
                width: 450
              )
            }
          }
        }
        id
      }
    }
  }
`
